import { FC } from 'react';
import { RouterLocation } from 'connected-react-router';

import urlParser from 'bloko/common/urlParser';

import { UserType } from 'lux/models/userType';
import { useSelector } from 'src/hooks/useSelector';

import ApplicantToEmployer from 'src/components/AccountLogin/components/AccountTypeSwitch/ApplicantToEmployer';
import EmployerToApplicant from 'src/components/AccountLogin/components/AccountTypeSwitch/EmployerToApplicant';

const getExpandURL = (userType: UserType, location: RouterLocation<unknown>) => {
    const url = urlParser(`${location.pathname}${location.search}`);
    url.params.role = userType;
    return url.href;
};

const AccountTypeSwitch: FC = () => {
    const anonymousUserType = useSelector((state) => state.anonymousUserType);
    const location = useSelector((state) => state.router.location);

    const onSwitchTo = (userType: UserType) => {
        window.location.assign(getExpandURL(userType, location));
    };

    if (anonymousUserType === UserType.Applicant) {
        return <ApplicantToEmployer onSwitch={() => onSwitchTo(UserType.Employer)} />;
    }

    return <EmployerToApplicant onSwitch={() => onSwitchTo(UserType.Applicant)} />;
};

export default AccountTypeSwitch;
