import { VSpacing, Card, Title, Button } from '@hh.ru/magritte-ui';
import BlokoButton, { ButtonType, ButtonKind, ButtonAppearance } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Header from 'src/components/AccountLogin/components/Title/Header';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

interface ApplicantToEmployerProps {
    onSwitch: () => void;
}

const TrlKeys = {
    header: 'employer.login.header',
    subheader: 'employer.login.subheader',
    action: 'employer.login.action.find',
};

const ApplicantToEmployer: TranslatedComponent<ApplicantToEmployerProps> = ({ trls, onSwitch }) => {
    const isMagritte = useMagritte();

    if (isMagritte) {
        return (
            <Card stretched padding={32} borderRadius={40} showBorder>
                <Title Element="h2" size="small" description={trls[TrlKeys.subheader]}>
                    {trls[TrlKeys.header]}
                </Title>
                <VSpacing default={20} />
                <Button onClick={onSwitch} mode="secondary" style="accent" stretched>
                    {trls[TrlKeys.action]}
                </Button>
            </Card>
        );
    }

    return (
        <div className="account-login-tile">
            <div className="account-login-tile-content-wrapper">
                <div className="account-login-tile-content">
                    <Header subheader={trls[TrlKeys.subheader]}>{trls[TrlKeys.header]}</Header>
                    <VSpacing default={8} />
                    <BlokoButton
                        type={ButtonType.Button}
                        kind={ButtonKind.Primary}
                        appearance={ButtonAppearance.Outlined}
                        onClick={onSwitch}
                        stretched
                    >
                        {trls[TrlKeys.action]}
                    </BlokoButton>
                </div>
            </div>
        </div>
    );
};

export default translation(ApplicantToEmployer);
