import { ReactNode } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { TwoFactorCheckStatus, LoginSteps } from 'src/components/AccountLogin/types';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import Header from 'src/components/AccountLogin/components/Title/Header';

interface EmployerLoginTitleProps {
    step: LoginSteps;
    verificationType: TwoFactorCheckStatus | null;
}

const TrlKeys = {
    header: 'employer.login.header',
    endorsement: 'resume.endorsement.login',
    forbiddenTitle: 'logon.forResponse.title',
    mailruErrorTitle: 'error.password.mismatch.password.reset.attention',
    twoFactorAuthTitleAccountBlocked: 'auth.twoFactor.title.accountBlocked',
    twoFactorAuthTitle: 'auth.twoFactor.title',
    subHeader: 'employer.login.subheader',
    subHeaderDuplicate: 'newemployer.duplicate.note.noname',
    subTitleEmployerLogin: 'auth.employer.login.sub.title',
    twoFactorTotpDescription: 'auth.twoFactor.totp.description',
    twoFactorDescription: 'auth.twoFactor.description',
    mailruErrorFirstDescription: 'error.password.mismatch.password.reset.first.paragraph',
    mailruErrorSecondDescription: 'error.password.mismatch.password.reset.second.paragraph',
    twoFactorAuthAccountBlockedDescription: 'auth.twoFactor.accountBlocked.description',
    twoFactorAuthAccountBlockedDescriptionSupport: 'auth.twoFactor.accountBlocked.descriptionSupport',
};

const EmployerLoginTitle: TranslatedComponent<EmployerLoginTitleProps> = ({ trls, step, verificationType }) => {
    const forbidden = useSelector(({ forbidden }) => forbidden);
    const { isEmployerLogin } = useSelector(({ router }) => router.location.query);

    let header: string | null = trls[TrlKeys.header];
    let dataQa: string | undefined;
    let subheader: string | ReactNode | null = null;

    if (step === LoginSteps.AccountBlocked) {
        header = trls[TrlKeys.twoFactorAuthTitleAccountBlocked];
    }

    if (isEmployerLogin) {
        subheader = trls[TrlKeys.subTitleEmployerLogin];
    }

    if (step === LoginSteps.NoAccessToEmailStep) {
        header = null;
        subheader = null;
    }

    if (step !== LoginSteps.Login) {
        subheader = null;
    }

    if (step === LoginSteps.OtpVerification) {
        header = null;
        subheader = null;
    }

    if (step === LoginSteps.MailruError) {
        header = trls[TrlKeys.mailruErrorTitle];
        subheader = (
            <>
                <p>{trls[TrlKeys.mailruErrorFirstDescription]}</p>
                <p>{trls[TrlKeys.mailruErrorSecondDescription]}</p>
            </>
        );
    }

    if (step === LoginSteps.TwoFactorStep) {
        dataQa = 'login-two-factor-title';
        header = trls[TrlKeys.twoFactorAuthTitle];
        subheader = <div data-qa="account-login-form">{trls[TrlKeys.twoFactorDescription]}</div>;

        if (
            verificationType === TwoFactorCheckStatus.TwoFactorTotpCheck ||
            verificationType === TwoFactorCheckStatus.TwoFactorTotpCheckWrongCode
        ) {
            subheader = <div data-qa="account-login-form">{trls[TrlKeys.twoFactorTotpDescription]}</div>;
        }
    }

    if (step === LoginSteps.AccountBlocked) {
        subheader = (
            <div data-qa="account-login-form">
                <p>{trls[TrlKeys.twoFactorAuthAccountBlockedDescription]}</p>
                <VSpacing default={8} />
                <p>{trls[TrlKeys.twoFactorAuthAccountBlockedDescriptionSupport]}</p>
            </div>
        );
    }

    if (forbidden) {
        header = trls[TrlKeys.forbiddenTitle];
    }

    return (
        <>
            {header && (
                <Header dataQa={dataQa} subheader={subheader}>
                    {header}
                </Header>
            )}
        </>
    );
};

export default translation(EmployerLoginTitle);
