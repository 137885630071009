import { useState } from 'react';

import authorizeBySocialNetworkButtonClick, {
    Kind,
} from '@hh.ru/analytics-js-events/build/xhh/common/authorization/authorize_by_social_network/authorize_by_social_network_button_click';
import { BrandedButton, useBreakpoint } from '@hh.ru/magritte-ui';
import {
    EsiaBrandSize24,
    VkBrandSize24,
    MailBrandSize24,
    OkBrandSize24,
    GoogleBrandSize24,
} from '@hh.ru/magritte-ui/service';
import SocialIcon, { SocialIconView } from 'bloko/blocks/socialIcon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { SocialNetworks, SocialNetworksCode } from 'lux/models/LoginForm';
import NoIndex from 'src/components/NoIndex';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import Postpone from 'src/components/SignInSignUpFlow/Postpone';
import { SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE } from 'src/components/SignInSignUpFlow/types';

const SOCIAL_LINK_ICONS = {
    [SocialNetworksCode.MAIL]: SocialIconView.Mail,
    [SocialNetworksCode.OK]: SocialIconView.OK,
    [SocialNetworksCode.VK]: SocialIconView.VK,
    [SocialNetworksCode.GPLUS]: SocialIconView.GPlus,
    [SocialNetworksCode.ESIA]: SocialIconView.ESIA,
    [SocialNetworksCode.TinkoffBusiness]: undefined,
};

const MAGRITTE_SOCIAL_LINK_ICONS = {
    [SocialNetworksCode.MAIL]: <MailBrandSize24 />,
    [SocialNetworksCode.OK]: <OkBrandSize24 />,
    [SocialNetworksCode.VK]: <VkBrandSize24 />,
    [SocialNetworksCode.GPLUS]: <GoogleBrandSize24 />,
    [SocialNetworksCode.ESIA]: <EsiaBrandSize24 />,
    [SocialNetworksCode.TinkoffBusiness]: undefined,
};

const SHORT_ICONS_LIST_LENGTH = 5;

interface SocialRoundedProps {
    socialNetworks: SocialNetworks[];
}

const TrlKeys = {
    socialTitle: {
        [SocialNetworksCode.MAIL]: 'socialNetworkTitle.mail',
        [SocialNetworksCode.OK]: 'socialNetworkTitle.ok',
        [SocialNetworksCode.VK]: 'socialNetworkTitle.vk',
        [SocialNetworksCode.GPLUS]: 'socialNetworkTitle.gplus',
        [SocialNetworksCode.ESIA]: 'socialNetworkTitle.esia',
    },
};

const SocialRounded: TranslatedComponent<SocialRoundedProps> = ({ trls, socialNetworks }) => {
    const vacancyId = useSelector(({ postponedActions }) => postponedActions?.vacancy?.vacancyId);
    const [showFull, setShowFull] = useState(SHORT_ICONS_LIST_LENGTH + 1 === socialNetworks.length);
    const isMagritte = useMagritte();
    const { isXS } = useBreakpoint();

    const icons = isMagritte ? MAGRITTE_SOCIAL_LINK_ICONS : SOCIAL_LINK_ICONS;

    const renderMoreButton = () => {
        if (isMagritte) {
            return (
                <BrandedButton
                    service={[]}
                    size="large"
                    mode="secondary"
                    data-qa={`account-login-social-show-more`}
                    onClick={() => setShowFull(true)}
                />
            );
        }
        return (
            <SocialIcon
                button
                view={SocialIconView.More}
                Element="span"
                data-qa={`account-login-social-show-more`}
                onClick={() => setShowFull(true)}
            />
        );
    };

    const renderSocialButton = ({ code, url, trl }: SocialNetworks) => {
        const view = icons[code];
        const isBrandedButtonExist = Object.keys(SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE).includes(code);

        if (!isBrandedButtonExist) {
            return null;
        }
        if (isMagritte) {
            return (
                <BrandedButton
                    service={SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE[code]}
                    mode="secondary"
                    Element={Postpone}
                    to={url}
                    rel="nofollow"
                    size={isXS ? 'medium' : 'large'}
                    aria-label={trl}
                    data-qa={`account-login-social-${code.toLowerCase()}`}
                    onClick={() => {
                        authorizeBySocialNetworkButtonClick({
                            kind: code.toLowerCase() as Kind,
                            vacancyId,
                        });
                    }}
                />
            );
        }
        return (
            <SocialIcon
                button
                view={view as SocialIconView}
                Element={Postpone}
                to={url}
                rel="nofollow"
                data-qa={`account-login-social-${code.toLowerCase()}`}
                title={trls[TrlKeys.socialTitle[code as keyof typeof TrlKeys.socialTitle]]}
                onClick={() => {
                    authorizeBySocialNetworkButtonClick({
                        kind: code.toLowerCase() as Kind,
                        vacancyId,
                    });
                }}
            />
        );
    };

    return (
        <NoIndex>
            <div className="account-login-social-icons">
                {socialNetworks
                    .filter(({ code }) => !!icons[code])
                    .map((socialNetwork, index) => {
                        if (showFull || index < SHORT_ICONS_LIST_LENGTH) {
                            return <span key={socialNetwork.code}>{renderSocialButton(socialNetwork)}</span>;
                        }
                        if (index === SHORT_ICONS_LIST_LENGTH) {
                            return <span key={socialNetwork.code}>{renderMoreButton()}</span>;
                        }
                        return null;
                    })}
            </div>
        </NoIndex>
    );
};

export default translation(SocialRounded);
