import { VSpacing } from '@hh.ru/magritte-ui';
import { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LoginForm, { useLoginForm } from 'src/components/AccountLogin/Form';
import ApplicantAuthMethodSwitcher from 'src/components/AccountLogin/components/ApplicantAuthMethodSwitcher';
import useAuthMethodSwitch, { AuthMethod } from 'src/components/AccountLogin/hooks/useAuthMethodSwitch';
import ApplicantSignInSignUpFlow from 'src/components/ApplicantSignInSignUpFlow';
import ApplicantAuthFormByCode from 'src/components/ApplicantSignInSignUpFlow/ApplicantAuthFormByCode';
import Button from 'src/components/MagritteRedesignComponents/Button';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

interface ApplicantLoginFormProps {
    onSignUp?: () => void;
    errorPageLogin?: boolean;
    applicantSignInStep?: string;
    onNextStep?: (step: string) => void;
}

const TrlKeys = {
    actionLogin: 'login.submit',
    actionByCode: 'login.action.by.code',
};

type LoginFormProps = ReturnType<typeof useLoginForm>;

const ApplicantLoginForm: TranslatedComponent<ApplicantLoginFormProps & { loginFormData: LoginFormProps }> = ({
    trls,
    onSignUp,
    onNextStep,
    applicantSignInStep,
    errorPageLogin,
    loginFormData,
}) => {
    const isMagritte = useMagritte();
    const { authMethod, onByPassword, onByCode } = useAuthMethodSwitch();

    const formByCode = (
        <ApplicantSignInSignUpFlow
            SignIn={ApplicantAuthFormByCode}
            onSignup={() => {
                onSignUp?.();
            }}
            onNextStep={onNextStep}
            step={applicantSignInStep}
            onClickByPassword={onByPassword}
            errorPageLogin={errorPageLogin}
        />
    );

    const formByPassword = (
        <LoginForm {...loginFormData}>
            <LoginForm.Username />
            <LoginForm.Password />
            <LoginForm.Captcha />
            <LoginForm.Error />
            <VSpacing default={isMagritte ? 4 : 12} />
            <div
                className={`account-login-actions ${
                    isMagritte && !errorPageLogin ? 'account-login-actions_column' : ''
                }`}
            >
                <Button
                    type="submit"
                    mode="primary"
                    style="accent"
                    size="large"
                    {...loginFormData.submitProps}
                    blokoComponentProps={{
                        kind: ButtonKind.Primary,
                        type: ButtonType.Submit,
                    }}
                >
                    {trls[TrlKeys.actionLogin]}
                </Button>
                <ApplicantAuthMethodSwitcher
                    data-qa="expand-login-by-code"
                    onClick={onByCode}
                    watchedFieldName="username"
                >
                    {trls[TrlKeys.actionByCode]}
                </ApplicantAuthMethodSwitcher>
            </div>
        </LoginForm>
    );

    if (authMethod === AuthMethod.ByCode) {
        return formByCode;
    }

    return formByPassword;
};

export default translation(ApplicantLoginForm);
