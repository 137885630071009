import { PropsWithChildren } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteDefaultLayout from 'src/app/layouts/MagritteLayouts/DefaultLayout';
import PageLayout, { DefaultLayout } from 'src/app/layouts/PageLayout';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';

const TrlKeys = {
    title: 'account.login.header',
};

const AccountLoginLayout: TranslatedComponent<PropsWithChildren> = ({ trls, children }) => {
    const isMagritte = useMagritte();

    return (
        <PageLayout title={trls[TrlKeys.title]} layout={isMagritte ? MagritteDefaultLayout : DefaultLayout}>
            {children}
        </PageLayout>
    );
};

export default translation(AccountLoginLayout);
