import { FC, PropsWithChildren } from 'react';
import { DefaultRootState } from 'react-redux';

import { LinkAppearance } from 'bloko/blocks/link';

import { authUrlUpdate } from 'lux/models/authUrl';
import Link from 'src/components/MagritteRedesignComponents/Link';
import useDispatchedFormField from 'src/hooks/react-final-form/useDispatchedFormField';

interface ApplicantAuthMethodSwitcherProps {
    watchedFieldName?: string;
    onClick?: () => void;
}

const ApplicantAuthMethodSwitcher: FC<ApplicantAuthMethodSwitcherProps & PropsWithChildren> = ({
    watchedFieldName,
    onClick,
    ...rest
}) => {
    const valueSelector = ({ authUrl }: DefaultRootState) => authUrl?.['login-field-value-entered'];
    const onDispatch = (value: string) => authUrlUpdate({ 'login-field-value-entered': value });
    const handleClick = useDispatchedFormField({
        watchedFieldName,
        valueSelector,
        onDispatch,
        handler: () => onClick?.(),
    });
    return (
        <Link
            blokoComponentProps={{
                appearance: LinkAppearance.Pseudo,
            }}
            {...rest}
            typography="label-2-regular"
            onClick={handleClick}
        />
    );
};

export default ApplicantAuthMethodSwitcher;
