import { useState, Fragment } from 'react';

import { VSpacing, Divider } from '@hh.ru/magritte-ui';
import { Link } from '@hh.ru/redux-spa-middleware';
import BlokoLink from 'bloko/blocks/link';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LoginForm, { useLoginForm } from 'src/components/AccountLogin/Form';
import LoginCard from 'src/components/AccountLogin/components/LoginCard/LoginCard';
import EmployerLoginTitle from 'src/components/AccountLogin/components/Title/EmployerLoginTitle';
import useEmployerOtpExperiment from 'src/components/AccountLogin/hooks/useEmployerOtpExperiment';
import { AuthMethod, LoginFormValues, LoginSteps } from 'src/components/AccountLogin/types';
import FlexibleRow from 'src/components/FlexibleRow';
import Button from 'src/components/MagritteRedesignComponents/Button';
import SocialButtons from 'src/components/SignInSignUpFlow/SocialButtons';
import translation from 'src/components/translation';
import useMagritte from 'src/hooks/useMagritte';
import useRegisterEmployerLink from 'src/hooks/useRegisterEmployerLink';
import { useSelector } from 'src/hooks/useSelector';

const TrlKeys = {
    actionSignup: 'employer.login.action.signup',
    registration: 'registration.form.register.self',
};

interface EmployerLoginCardProps {
    onNextStep?: () => void;
}

const EmployerLoginCard: TranslatedComponent<EmployerLoginCardProps> = ({ trls, onNextStep }) => {
    const isEmployerOtpExperiment = useEmployerOtpExperiment();
    const [formValues, setFormValues] = useState<LoginFormValues>();
    const { elementRef, dataQa: employerLinkDataQa, to, onClick } = useRegisterEmployerLink();
    const { isEmployerLogin: isRedirectedFromApplicantForm } = useSelector(({ router }) => router.location.query);
    const shouldShowEmployerOtp = isEmployerOtpExperiment && !isRedirectedFromApplicantForm;
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const isMagritte = useMagritte();
    const loginFormData = useLoginForm({
        isEmployerForm: true,
        initialValues: formValues,
        initialAuthMethod: shouldShowEmployerOtp ? AuthMethod.ByCode : AuthMethod.ByPassword,
        onStepChange: () => onNextStep?.(),
        onSubmitResult: ({ values }) => {
            setFormValues(values);
        },
    });

    const isFullView = loginFormData.step === LoginSteps.Login && !isRedirectedFromApplicantForm;
    const shouldShowUsername = (isMagritte && !isRedirectedFromApplicantForm) || !isMagritte;
    const Wrapper = isFullView || (!isFullView && isMagritte) ? LoginCard : Fragment;

    return (
        <Wrapper>
            <EmployerLoginTitle step={loginFormData.step} verificationType={loginFormData.verificationType} />
            <LoginForm {...loginFormData}>
                {shouldShowUsername && <LoginForm.Username />}
                {loginFormData.authMethod === AuthMethod.ByPassword && <LoginForm.Password />}
                <LoginForm.Captcha />
                <LoginForm.Error />
                {isMagritte && <VSpacing default={4} />}
                {isEmployerOtpExperiment ? (
                    <FlexibleRow>
                        {shouldShowEmployerOtp && <LoginForm.AuthMethodSwitcher />}
                        {loginFormData.authMethod === AuthMethod.ByPassword && <LoginForm.PasswordRecovery />}
                    </FlexibleRow>
                ) : (
                    <LoginForm.RememberMe shouldShowPasswordRecovery={!isMagritte || !isRedirectedFromApplicantForm} />
                )}
                <VSpacing default={isMagritte ? 24 : 12} />
                <LoginForm.Submit />
                {!isEmployerOtpExperiment && isMagritte && Boolean(isRedirectedFromApplicantForm) && (
                    <>
                        <VSpacing default={12} />
                        <LoginForm.PasswordRecovery button />
                    </>
                )}
            </LoginForm>
            {isFullView && (
                <>
                    {!!socialNetworks?.length && <SocialButtons socialNetworks={socialNetworks} />}
                    <Divider marginTop={24} marginBottom={24} />
                    {isMagritte ? (
                        <Button
                            Element={Link as unknown as 'a'}
                            mode="tertiary"
                            style="accent"
                            size="large"
                            stretched
                            disableVisited
                            data-qa={employerLinkDataQa}
                            onClick={onClick}
                            to={to}
                            ref={elementRef}
                        >
                            {trls[TrlKeys.registration]}
                        </Button>
                    ) : (
                        <BlokoLink
                            disableVisited
                            Element={Link}
                            data-qa={employerLinkDataQa}
                            onClick={onClick}
                            to={to}
                            ref={elementRef}
                        >
                            {trls[TrlKeys.actionSignup]}
                        </BlokoLink>
                    )}
                </>
            )}
        </Wrapper>
    );
};

export default translation(EmployerLoginCard);
