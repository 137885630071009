import { useState, FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';

import LoginCard from 'src/components/AccountLogin/components/LoginCard/LoginCard';
import ApplicantLoginTitle from 'src/components/AccountLogin/components/Title/ApplicantLoginTitle';
import useLoginForm from 'src/components/AccountLogin/hooks/useLoginForm';
import SocialNewIcons from 'src/components/SignInSignUpFlow/SocialRounded';
import useIsIframeView from 'src/hooks/useIsIframeView';
import useMagritte from 'src/hooks/useMagritte';
import { useSelector } from 'src/hooks/useSelector';

import ApplicantLoginForm from 'src/components/AccountLogin/CombinedLoginCards/ApplicantLoginForm';

interface ApplicantLoginCardProps {
    onSignUp?: () => void;
}

const ApplicantLoginCard: FC<ApplicantLoginCardProps> = ({ onSignUp }) => {
    const { socialNetworks } = useSelector(({ loginForm }) => loginForm);
    const isIframeView = useIsIframeView();
    const [applicantSignInStep, setApplicantSignInStep] = useState<string>();
    const [isTileView, setIsTileView] = useState(true);
    const shouldShowSocialNetworks = !!socialNetworks && !isIframeView;
    const isMagritte = useMagritte();
    const loginFormData = useLoginForm();

    const form = (
        <ApplicantLoginForm
            onSignUp={() => onSignUp?.()}
            onNextStep={(step) => {
                setApplicantSignInStep(step);
                setIsTileView(false);
            }}
            applicantSignInStep={applicantSignInStep}
            loginFormData={loginFormData}
        />
    );

    if (!isTileView) {
        return form;
    }

    return (
        <LoginCard>
            <ApplicantLoginTitle step={loginFormData.step} verificationType={loginFormData.verificationType} />
            {form}
            {shouldShowSocialNetworks && (
                <>
                    <VSpacing default={isMagritte ? 24 : 20} />
                    {!!socialNetworks && <SocialNewIcons socialNetworks={socialNetworks} />}
                </>
            )}
        </LoginCard>
    );
};

export default ApplicantLoginCard;
