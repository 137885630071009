import { BrandedButtonService } from '@hh.ru/magritte-ui';

import { SocialNetworksCode } from 'lux/models/LoginForm';

export const SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE: Record<SocialNetworksCode, BrandedButtonService> = {
    [SocialNetworksCode.MAIL]: 'mail',
    [SocialNetworksCode.OK]: 'ok',
    [SocialNetworksCode.VK]: 'vk',
    [SocialNetworksCode.GPLUS]: 'google',
    [SocialNetworksCode.ESIA]: 'esia',
    [SocialNetworksCode.TinkoffBusiness]: 't-bank',
} as const;

export default SOCIAL_NETWORK_TO_BRANDED_BUTTON_SERVICE;
